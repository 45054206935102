<template>
  <section :class="$style.blogArticles">
    <div :class="$style.wrapper">
      <el-button
        type="primary"
        style="margin: 16px 0 24px"
        @click="
          $router.push($options.ADDWINE_ADMIN_ROUTES.BLOG.ARTICLES.CREATE)
        "
      >
        Создать
      </el-button>
    </div>
    <el-table :data="articles" stripe>
      <el-table-column prop="name" label="Название статьи"> </el-table-column>
      <el-table-column prop="category" label="Категория"> </el-table-column>
      <el-table-column prop="isActive" label="Активный" width="200">
        <template slot-scope="scope">
          <input
            type="checkbox"
            onclick="return false"
            :checked="scope.row.isActive"
            style="pointer-events: none"
          />
        </template>
      </el-table-column>
      <el-table-column width="150">
        <template slot-scope="scope">
          <ActionButtons
            :class="$style.buttons"
            :edit-link="
              getRoute({
                route: $options.ADDWINE_ADMIN_ROUTES.BLOG.ARTICLES.ARTICLE,
                params: { id: scope.row.id },
              })
            "
            @delete="remove(scope.row)"
            :view-link="getViewLink(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :class="$style.pagination"
      layout="prev, pager, next"
      :page-size="limit"
      :total="total"
      :current-page.sync="page"
      @current-change="getList"
      background
    >
    </el-pagination>
  </section>
</template>

<script>
import delivery from '@/delivery'
import ActionButtons from '@/components/atoms/ActionsButtons.vue'

import {
  ADDWINE_ADMIN_ROUTES,
  getRoute,
  ADDWINE_ROUTES,
} from '@/constants/routing'
import { PROJECTS } from '@/constants/projects'
import addQueryParamsAddwine from '@/mixins/addQueryParamsAddwine'

export default {
  mixins: [addQueryParamsAddwine(function(params) { this.getList(params); })],
  components: { ActionButtons },
  ADDWINE_ADMIN_ROUTES,
  PROJECTS,
  ADDWINE_ROUTES,
  data() {
    return {
      articles: [],
      total: 0,
      page: 1,
      limit: 20,
    }
  },
  created() {
    this.getList()
  },
  methods: {
    initializeFromQuery() {
      this.page = parseInt(this.$route.query.page, 10) || 1
      this.limit = this.$route.query.limit || 20
      this.articles = this.$route.query.articles || []
    },
    async getList() {
      const loading = this.$loading({
        lock: true,
      })
      const { value, error } =
        await delivery.ContentServiceCore.BlogArticles.getList({
          limit: this.limit,
          page: this.page,
        })
        this.updatedQueryParams({
          limit: this.limit,
          page: this.page,
        });
      loading.close()

      if (error) return

      this.articles = value.data
      this.total = value.meta.count
    },

    async remove(article) {
      const isConfirm = confirm(
        `Вы точно хотите удалить статью: ${article.name}?`,
      )
      if (!isConfirm) {
        return
      }
      const res = await delivery.ContentServiceCore.BlogArticles.delete(
        article.id,
      )
      if (!res.error) {
        this.getList()
      }
    },
    getRoute(route, params) {
      return getRoute(route, params)
    },
    getViewLink(row) {
      if (!row.categories.length) return ''
      return getRoute({
        route: ADDWINE_ROUTES.ARTICLES.ARTICLE,
        params: {
          slug: row.slug,
          categorySlug: row.categories[0]?.slug,
        },
        site: PROJECTS.ADDWINE,
      })
    },
  },
}
</script>

<style lang="scss" module>
.blogArticles {
  padding: 1rem;
  .wrapper {
    @include stickyWrapper;
  }
  .pagination {
    @include pagination;
  }
}
</style>
